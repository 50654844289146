<template>
    <span>
        I am Footer
        
        <slot />
    </span>
    <div>
        <NuxtLink to="/">Home page</NuxtLink>
    </div>
    <div>
        <NuxtLink to="/login">Login page</NuxtLink>
    </div>
    <div>
        <NuxtLink to="/about">About page</NuxtLink> 
    </div>
    <div>
        <NuxtLink to="/profile">Profile page</NuxtLink> 
    </div>
</template>